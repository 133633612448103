import styles from './index.module.scss';
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

export default function MainButton(props) {

  return (
    <a href={props.href} className={styles['button']}>{props.children}</a>
  )
}
