import styles from './index.module.scss';
import { useState } from 'react';
import logo from '../../assets/media/img-navbar-logo.png';
import close from '../../assets/media/icon-navbar-close.svg';
import { useNavigate } from 'react-router-dom';
import MainButton from '../mainButton';

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleToogleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }
  const handleGoToRegister = () => {
    setIsMenuOpen(false)
    navigate('/register')
  }

  return (
    <nav className={styles['navbar']}>
      <div className={styles['navbar-logo']}>
        <img onClick={() => navigate('/')} className={styles['navbar-logo-icon']} src={logo} alt='Logo' />
      </div>
      <ul className={styles['navbar-links']}>
        <li onClick={() => navigate('/#summer_program')} className={styles['navbar-link']}>Camps and Activities</li>
        <li className={styles['navbar-menu-link']}>
                <MainButton href="/register">Join us today</MainButton>
              </li>
      </ul>
      <button onClick={handleToogleMenu} className={styles['navbar-menu-action']}>
      </button>
      {
        isMenuOpen && (
          <div className={styles['navbar-menu']}>
            <img src={close} onClick={handleToogleMenu} className={styles['navbar-menu-close']} alt='' />
            <ul className={styles['navbar-menu-links']}>
              <li onClick={() => navigate('/#summer_program')} className={styles['navbar-menu-link']}>Camps and Activities</li>
              <li className={styles['navbar-menu-link']}>
                <MainButton href="/register">Join us today</MainButton>
              </li>
            </ul>
          </div>
        )
      }
    </nav>
  )
}
