import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Register from './pages/register';
import Layout from './layout';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Snackbar from './components/snackbar';


function App() {
  const contactRef = useRef(null);
	const [snackbar, setSnackbar] = useState({
		display: false,
		message: '',
		variant: 'success',
	});
  const getLayout = (component) => {
    return <Layout contactRef={contactRef}>{component}</Layout>;
  };
  return (
    <>
      <Helmet>
        <title>NEOWELL</title>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={getLayout(<Home contactRef={contactRef} />)} />
          <Route path='/register' element={getLayout(<Register setSnackbar={setSnackbar} />)} />
        </Routes>
      </BrowserRouter>
      <Snackbar snackbar={snackbar} setSnackbar={setSnackbar} />

      </>
  );
}

export default App;
