import { useForm } from 'react-hook-form';

export function useHookForm(props) {
	const {
		register,
		handleSubmit,
		resetField,
		formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
		setValue,
		getValues,
		trigger,
		clearErrors,
	} = useForm({ mode: 'onSubmit' });

	const fullNameRegister = register('full_name', {
		onChange: (e) => {
			clearErrors('full_name');
			const full_name = e.target.value;
			const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
			const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
			if (full_nameRegex.test(displayValue)) {
				setValue('full_name', displayValue);
			} else {
				setValue('full_name', displayValue.trim());
			}
			if (!e.target.value) {
				resetField('full_name');
			}
		},
		required: {
			value: true,
			message: 'Name is required',
		},
		maxLength: {
			value: 20,
			message: 'Max Characters has been exceeded',
		},
	});

	const ageRegister = register('age', {
		onChange: (e) => {
			clearErrors('age');
			const age = e.target.value;
			const trimmedAge = age.replace(/\D/g, '');
			setValue('age', trimmedAge);
		},
		required: {
			value: true,
			message: 'Age is required',
		},
		validate: (value) => {
			return (Number(value) >= 4 && Number(value) <= 14) || 'Aage must be between 4 and 21';
		},
	});


	const parentRelationshipRegister = register('parent_relationship', {
		onChange: (e) => {
			clearErrors('parent_relationship');
			const full_name = e.target.value;
			const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
			const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
			if (full_nameRegex.test(displayValue)) {
				setValue('parent_relationship', displayValue);
			} else {
				setValue('parent_relationship', displayValue.trim());
			}
			if (!e.target.value) {
				resetField('parent_relationship');
			}
		},
		required: {
			value: false,
			message: 'Guardian relation is required',
		},
		maxLength: {
			value: 50,
			message: 'Max Characters has been exceeded',
		},
	});

	const parentPhoneRegister = register('parent_phone_number', {
		onChange: (e) => {
			clearErrors('parent_phone_number');
			const phoneNumber = e.target.value;
			const trimmedNumber = phoneNumber.replace(/\D/g, '');
			setValue('parent_phone_number', trimmedNumber);
		},
		required: {
			value: true,
			message: 'mobile number is required',
		},
	});

	const parentEmailRegister = register('parent_email', {
		onChange: (e) => {
			clearErrors('parent_email');
			setValue('parent_email', e.target.value);
			clearErrors('parent_email');
			if (!e.target.value) {
				resetField('parent_email');
			}
		},
		required: {
			value: true,
			message: 'Email is Required',
		},
		validate: (value) => {
			if (!value) {
				return true;
			} else {
				const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
				return regex.test(value) || 'Email is not valid';
			}
		},
	});


	return {
		handleSubmit,
		errors,
		isDirty,
		isValid,
		fullNameRegister,
		ageRegister,
		parentRelationshipRegister,
		parentPhoneRegister,
		parentEmailRegister,
		getValues,
		dirtyFields,
		isSubmitting,
		clearErrors,
		setValue,
		trigger,
	};
}
