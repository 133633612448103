import kids from '../../assets/media/img-kids.png';
import styles from './index.module.scss';
import { useHookForm } from '../../hooks/hook-form';
import FormInput from '../../components/formInput';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Loader from '../../components/loader';
import { Box, Chip, MenuItem, OutlinedInput, Select } from '@mui/material';

export default function Register({ setSnackbar }) {
  const [selectedGrade, setSelectedGrade] = useState('');
  const [trigger, setTrigger] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    fullNameRegister,
    ageRegister,
    parentEmailRegister,
    parentPhoneRegister,
    parentRelationshipRegister,
    isValid,
    isDirty,
    errors,
    handleSubmit,
  } = useHookForm();


  const isDisabled = () => {
    return !isValid;
  }

  const handleGradeChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedGrade(value);
  }

  const handleFormSubmit = async (data) => {
    if(isDisabled())  return;
    setLoading(true);
    console.log(selectedGrade);
    try {
      const payload = {
        name: data.full_name,
        age: data.age,
        email: data.parent_email,
        phone_number: data.parent_phone_number,
        gender: selectedGrade[0],
        medical_conditions: data.parent_relationship,
      };
      console.log(payload);

      const response = await fetch('https://app.neo-well.com/contactus/create', {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 201) {
        setSnackbar({
          display: true,
          message: 'Registration successful',
          variant: 'success',
        })
      } else {
        setSnackbar({
          display: true,
          message: 'Registration failed try again later',
          variant: 'error',
        })
      }
    } catch (error) {
      setSnackbar({
        display: true,
        message: 'Registration failed try again later',
        variant: 'error',
      })
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>NEOWELL | REGISTER</title>
      </Helmet>
      <div className={styles["container"]}>
        <div className={styles["container-col"]}>
          <div>
            <h1 className={styles["subheader"]}>Join Us at MARASSI  beach</h1>
            <p className={styles["header"]}>
              OUR DREAM IS TO FIX THE FITNESS OPPORTUNITY GAP BY PROVIDING STRUCTURED FITNESS
            </p>
          </div>
          <img src={kids} alt="Football at Neo Well" />
        </div>
        <div className={styles["container-col"]}>
          <form className={styles['register-form']} noValidate onSubmit={handleSubmit(handleFormSubmit)}>
            <div className={styles['register-form-input-container']}>
              <FormInput maxLength={20} formRegister={fullNameRegister} error={errors['full_name']} placeholder={'Contact name'} />
            </div>

            <div className={styles['register-form-input-container']}>
              <FormInput maxLength={40} formRegister={parentEmailRegister} error={errors['parent_email']} placeholder={'E-mail'} />
            </div>
            <div className={styles['register-form-input-container']}>
              <FormInput maxLength={20} formRegister={parentPhoneRegister} error={errors['parent_phone_number']} placeholder={'Phone number'} />
            </div>
            <div className={styles['register-form-input-container']}>

              <FormInput maxLength={2} formRegister={ageRegister} error={errors['age']} placeholder={'Age'} />

              <div className={styles['options-container']}>
                <Select
                  labelId="demo-multiple-chip-label-grade"
                  id="demo-multiple-chip-grade"
                  sx={{
                    width: '100%',
                    minHeight: '2.6rem',
                    background: '#fff',
                    borderBottom: '2px solid',
                    borderRadius: '0rem',
                    padding: '0px !important',
                    outline: 'none !important',
                    fontSize: '1.3rem !important',
                    borderColor: trigger && !selectedGrade ? '#1C5EF6' : '#1C5EF6',
                    '& *': {
                      fontFamily: 'montserrat !important',
                      fontSize: '1.3rem !important',
                      border: '0 !important',
                      color: '#1C5EF6 !important',
                      paddingTop: '0px !important',
                      paddingBottom: '0px !important',

                    },
                    '& p': {
                      fontSize: '1.3rem !important',
                      color: '#1C5EF6 !important'
                    }
                  }}
                  displayEmpty
                  value={selectedGrade}
                  onChange={handleGradeChange}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => {
                    if (selected.length === 0)
                      return (
                        <p>
                           Gender
                        </p>
                      );
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: 0.5 }}>
                        <Chip key={selected} label={selected} />
                      </Box>
                    )
                  }}
                >
                  {["Female", "Male"].map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      sx={{
                        fontFamily: 'montserrat !important',
                        fontSize: '1.3rem !important',
                        border: '0 !important',
                        color: '#000c78 !important'
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {trigger && !selectedGrade &&
                  <p className={styles['form-error']}>Gender is required</p>
                }
              </div>
            </div>
            <div className={styles['register-form-input-container']}>

              <FormInput maxLength={20} formRegister={parentRelationshipRegister} error={errors['parent_relationship']} placeholder={'Any Medical condition'} />
            </div>
            <button
              onClick={() => setTrigger(true)}
              className={styles['register-form-action']}
              type='submit'
              disabled={loading}
            >
              {loading ? <Loader /> : 'Register'}
            </button>
            <div className={styles["container-contact"]}>
              <ul>
                <li><h5>Location</h5></li>
                <li>93 xx street Sheraton, <br />
                  CairoAddress Beach, Marassi</li>
              </ul>
              <ul>
                <li><h5>Contact</h5></li>
                <li>0100 000 0000</li>
                <li>hello@neowell.com</li>
              </ul>
            </div>
          </form>
        </div>
       
      </div>
      <div className={styles["footer-passion"]}>
          <span>All copyrights reserved &#169; 2024</span>
          <span>MADE WITH PASSION BY <a href="https://uniparticle.com" target="_blank">UNIPARTICLE</a></span>
        </div>
    </>
  )
}
