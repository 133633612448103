import styles from "./index.module.scss";
import { Helmet } from "react-helmet";
import footballNeoWell from "../../assets/media/img-neowell-football.webp";
import patternNeoWell from "../../assets/media/img-neowell-pattern.webp";
import mapPin from "../../assets/media/icon-map-marker.webp";
import programActivities from "../../assets/media/program-activities.webp";
import fitnessLower from "../../assets/media/img-fitness-left-lower.webp";
import fitnessLeft from "../../assets/media/img-fitness-left.webp";
import fitnessLeftCropped from "../../assets/media/img-fitness-left-cropped.webp";
import fitnessRight from "../../assets/media/img-fitness-right.webp";
import freeStyleFootball from "../../assets/media/img-free-style-football.webp";
import rightUpperRight from "../../assets/media/img-right-upper-right.webp";
import rightUpperLeft from "../../assets/media/img-right-upper-left.webp";
import trianglesBg from "../../assets/media/img-triangles-bg.webp";
import rightArrow from "../../assets/media/icon-right-arrow.webp";
import footballNeoWellResp from "../../assets/media/img-neo-well-hero.png";
import MainButton from "../../components/mainButton";

import membership from '../../assets/media/membership.webp';
import membership1 from "../../assets/media/icon-membership-1.webp";
import membership23 from "../../assets/media/icon-membership-2-3.webp";
import membership4 from "../../assets/media/icon-membership-4.webp";
import blueLogo from "../../assets/media/img-logo-with-blue-background.png";
import socialFacebook from "../../assets/media/icon-social-facebook.svg";
import socialWhatsapp from "../../assets/media/icon-social-whatsapp.svg";
import socialInstagram from "../../assets/media/icon-social-instagram.svg";
import kids from '../../assets/media/img-kids-withbg.png';
import { useNavigate } from "react-router-dom";


export default function Home() {
  return (
    <>
      <Helmet>
        <title>NEOWELL</title>
      </Helmet>
      <section className={`${styles["home"]}`}>
        <div className={styles["home-upper"]}>
          <img
            src={footballNeoWell}
            alt="Football at Neo Well"
            className={styles["football-bg"]}
          />
          <img
            src={footballNeoWellResp}
            alt="Football at Neo Well"
            className={styles["football-neo-well-resp"]}
          />
          <img
            src={trianglesBg}
            alt="triangles hero at Neo Well"
            className={styles["triangles-bg"]}
          />

          <div className={styles["text-hero"]}>
            <p>
              NEO WELL was created to introduce a unique wellness concept and
              innovative physical education experience and class structure for
              underserved kids and youth.
            </p>

            <a href="/register" className={styles["join-us-btn"]}>Join us today</a>
          </div>

          <a className={styles["read-more-container"]} href="/#about">
            <p>
              Read
              <br />
              More
            </p>
            <img src={rightArrow} alt="" />
          </a>
        </div>

        <div className={styles["home-lower"]} id="about">
          <div className={styles["home-lower-content"]}>
            <p className={styles["subheader"]}>about neo well</p>
            <p className={styles["header"]}>
              Promoting and Enhancing Physical Education in Schools and
              Communities
            </p>
            <p className={styles["desc"]}>
              Since 2010, we have been a professional wellness provider in
              Egypt, leading the industry with innovative solutions in digital
              infrastructure. Our expertise and dedication have positioned us at
              the forefront of wellness services, ensuring we deliver the
              highest quality experiences to our clients.
            </p>
            {/* <button className={styles["read-more"]}>Read More</button> */}
          </div>

          <div className={styles["pattern-img"]}>
            <img src={patternNeoWell} alt="Pattern at Neo Well" />
          </div>
        </div>
      </section>

      <section className={styles["find-us"]} >
        <div className={styles["find-us-content"]}>
          <p className={styles["subheader"]}>Join us</p>
          <p className={styles["header"]}>neo well Summer Camp</p>
          <p className={styles["desc"]}>
            Neo Well Summer Camp is designed to provide a fun, educational, and
            enriching experience for children and youth.
            <br />
            <br />
            Our camp focuses on promoting wellness, physical activity, and
            personal growth through a variety of engaging activities and
            programs.
          </p>
        </div>

        <a target="_blank" href="https://maps.app.goo.gl/ec1tBWqoDK77sWrh6?g_st=com.google.maps.preview.copy" className={styles["find-us-triangle"]}>
          <img src={mapPin} alt="Map Pin" />
          <p className={styles["pin-text"]}>Find us at</p>
          <p className={styles["pin-address"]}>MARASSI Address beach</p>
          <button className={styles["join-us-btn"]}>Join Us Today</button>
        </a>
      </section>

      <section className={styles["program-activities"]}>
        <div className={styles["text-bottom-container"]}>
          <p className={styles["text-bottom"]}>Program Activities</p>
        </div>
        <div className={styles["content-container"]}>
          <div className={styles["program-activities-content"]}>
            <p>
              Neo Well Summer Camp is designed to provide a fun, educational,
              and enriching experience for children and youth. Our camp focuses
              on promoting wellness, physical activity, and personal growth
              through a variety of engaging activities and programs.
            </p>
            <MainButton href="/register">Join Us Today</MainButton>
          </div>
        </div>

        <div className={styles["program-activities-img"]}>
          <img src={programActivities} alt="Program Activities" />
        </div>
      </section>

      <section className={styles["programs"]}>
        <div className={styles["programs-upper"]}>
          <div className={styles["programs-upper-left"]}>
            <div className={styles["left-half"]}>
              <div className={styles["content"]}>
                <p className={styles["fitness-title"]}>Performance or Functional Movement</p>
                <p className={styles["fitness-desc"]}>
                  We prioritize the health and well-being of every camper
                  through our carefully designed daily fitness sessions. These
                  sessions are tailored to promote physical activity, enhance
                  overall fitness, and instill healthy habits that last a
                  lifetime.
                </p>
              </div>
              <div className={styles["img-container"]}>
                <img src={fitnessLower} alt="Fitness Lower" />
              </div>
            </div>
            <div className={styles["right-half"]}>
              <img
                src={fitnessLeft}
                alt="Fitness Left"
                className={styles["fitness-left"]}
              />
              <img
                src={fitnessLeftCropped}
                alt="Fitness Left"
                className={styles["fitness-left-cropped"]}
              />
            </div>
          </div>

          <div className={styles["programs-upper-right"]}>
            <img src={fitnessRight} alt="Fitness Right" />
            <div className={styles["content"]}>
              <p className={styles["header"]}>Gymnastics</p>
              <p className={styles["desc"]}>
                We offer an exciting and comprehensive gymnastics program
                designed to enhance physical fitness, coordination, and
                self-confidence in children.
              </p>
            </div>
          </div>
        </div>

        <div className={styles["programs-lower"]}>
          <div className={styles["programs-lower-left"]}>
            <div className={styles["content"]}>
              <p className={styles["header"]}>Skill Development (FREESTYLE Football)</p>
              <p className={styles["desc"]}>
                Unleash your child's inner football star with Freestyle Football
                at Neo Well Summer Camp!
                <br />
                <br />
                Our Freestyle Football program is designed to introduce kids to
                the exciting world of freestyle soccer, where creativity, skill,
                and flair take center stage.
                <br />
                <br />
              </p>
              <p className={styles["list-header"]}>
                Key Components of Our
                <br />
                Freestyle Football Program
              </p>
              <ul>
                <li>Warm-Up and Conditioning</li>
                <li>Basic Freestyle Tricks</li>
                <li>Intermediate Freestyle Skills</li>
                <li>Creative Expression</li>
              </ul>
              <a href="/register" className={styles["join-us-btn"]}>Join Us Today</a>
            </div>
            <div className={styles["img-container"]}>
              <img
                src={freeStyleFootball}
                alt=""
                className={styles["free-style-football"]}
              />
            </div>
          </div>

          <div className={styles["programs-lower-right"]}>
            <div className={styles["imgs-container"]}>
              <img
                src={rightUpperLeft}
                alt=""
                className={styles["right-upper-left"]}
              />
              <img
                src={rightUpperRight}
                alt=""
                className={styles["right-upper-right"]}
              />
            </div>
            <div className={styles["content"]}>
              <p className={styles["header"]}>BREAK DANCE</p>
              <p className={styles["desc"]}>
                Get ready to groove and move with Break Dance at Neo Well Summer
                Camp! Our Break Dance program is designed to introduce children
                to the vibrant world of breakdancing, encouraging them to
                express themselves through dynamic movements and creative
                routines.
              </p>
            </div>

            <img
              src={rightUpperRight}
              alt=""
              className={styles["right-upper-right-resp"]}
            />
          </div>
        </div>
      </section>

      <section className={styles["memberships"]}>
        <div className={styles["memberships-container"]}>
          <div className={styles["left-side"]}>

            <div className={styles["content"]}>
              <p className={styles["sub-header"]}>Membership and day passes</p>
              <p className={styles["header"]}>
                flexible membership options and day passes to suit your wellness
                needs and schedule
              </p>
            </div>
            <div className={styles["cards"]}>
              <div className={styles["green-card"]}>
                <img src={membership1} alt="" />
                <p className={styles["desc"]}>Membership available </p>
                <p className={styles["bold-desc"]}>from July 1st – Sept 30 <br></br> Every day from 4PM - 7PM</p>
              </div>

              <div className={styles["green-card"]}>
                <img src={membership23} alt="" />
                <p className={styles["desc"]}>Special offers</p>
                <p className={styles["bold-desc"]}>Group pass</p>
              </div>

              <div className={styles["green-card"]}>
                <img src={membership23} alt="" />
                <p className={styles["desc"]}>Kids Activity Pricing</p>
                <p className={styles["bold-desc"]}>One hour (EGP400 - $8) <br></br>  Two hours (EGP750 - $15) <br></br> Three Hours (EGP1,000 - $20)<br></br> Week Pass (EGP3,500 - $70) </p>
              </div>

              <div className={styles["orange-card"]}>
                <img src={membership4} alt="" />
                <a href="/register" className={styles["desc"]}>Register <br />Today</a>
              </div>
            </div>

          </div>

          <div className={styles["right-side"]}>
            <img src={membership} alt="" />
          </div>
        </div>
      </section>

      <footer className={styles["footer"]}>
        <div className={styles["footer-cols"]}>
          <div className={styles["footer-links"]}>

            <div className={styles["footer-links-about"]}>
              <div>
                <img className={styles["footer-links-about-logo"]} src={blueLogo}></img>
                <h4>Fun, educational, and enriching experience for children and youth</h4>
              </div>
              <div className={styles["footer-links-about-social"]}>
                <a href="https://www.facebook.com/profile.php?id=61561237937302" target="_blank"><img src={socialFacebook}></img></a>
                {/* <a href="#"><img src={socialWhatsapp}></img></a> */}
                <a href="https://www.instagram.com/neowell__/?hl=en" target="_blank"><img src={socialInstagram}></img></a>
              </div>
            </div>

            <div className={styles["footer-links-contact"]}>
              <ul>
                <li><h5>Location</h5></li>
                <li><a href="https://maps.app.goo.gl/7Z56oGqurCQcPx629?g_st=iw" target="_blank"> Address Beach Resort<br></br>Marassi - Egypt</a></li>
              </ul>
              <ul>
                <li><h5>Contact</h5></li>
                <li><a href="/register">Reach us</a></li>
                {/* <li>hello@neowell.com</li> */}
              </ul>
            </div>
          </div>
          <div className={styles["footer-media"]}>
            <img src={kids} alt="Football at Neo Well" />
          </div>
        </div>
        <div className={styles["footer-passion"]}>
          <span>All copyrights reserved &#169; 2024</span>
          <span>MADE WITH PASSION BY <a href="https://uniparticle.com" target="_blank">UNIPARTICLE</a></span>
        </div>
      </footer>

    </>
  );
}
